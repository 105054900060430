<!-- 多联机--空调--模块入口 -->
<!-- 2023/4/6   lht    1.优化页面 2.优化页面缓存机制-->

<template>
    <div id="airConditionModuleInlet">
      <div class="header">
        <my-indextitle :imgurl="imgurl">{{ title }}</my-indextitle>
        <my-navdar :list="list"></my-navdar>
      </div>
      <div class="main">
        <transition name="slide-fade" mode="out-in">
            <keep-alive>
                <router-view class="view"></router-view>
            </keep-alive>
        </transition>
      </div>
    </div>
  </template>
  
  <script>
  import indexTitle from '../../components/Title/indexTitle'
  import navdar from '../../components/Navdar/horizontal'
  
  export default {
    name:'airConditionModuleInlet',
    data() {
      return {
        flag:"VRFAC",//暂时写死
        imgurl: require("../../../public/images/moduleimg/vrfac.png"),
        list: JSON.parse(sessionStorage.getItem("sitemenu")) || []
      }
    },
    // 获取名称
    computed: {
      title: function () {
        let name = this.$store.getters.siteName
        return name
      }
    },
    mounted() {
        this.getMeunData()
    },
    watch: {
      /// 监听多语言切换，导航栏数据同步切换
      "$store.getters.loading": {
        handler: function (newValue, oldValue) {
            this.getMeunData()
        },
        deep: true
      }
    },
    methods: {
        // 获取导航数据（分页模块数据）数据
        getMeunData(){
            const value = this.$store.getters.loading
            const Code = this.$route.query.Code
            if (!value) {
                this.$store.commit("menu/SITE_MENU", Code)
                const flag = this.flag
                const _arr = JSON.stringify(this.$store.getters.siteMenu)
                const arr = JSON.parse(_arr)
                const list = arr.map(item => {
                    item.code = flag + '_' + item.code
                    item.children.map(index => { return index.code = flag + '_' + index.code })
                    return item
                })
                this.list = list
            }
        }
    },
    components: {
      'my-indextitle': indexTitle,
      'my-navdar': navdar
    }
  }
  </script>
  
  <style scoped>
  /* 门禁设备跟标签 */
  #airConditionModuleInlet {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px #bdbcbc;
    background: #ffffff;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
  }
  .main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;  
  }
  .header {
    height: 100px;
    padding: 0px 20px 0px 20px;
  }
  .view{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .slide-fade-enter-active {
    transition: all 0.8s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  </style>